<script setup lang="ts">
import type { KeenSliderOptions, KeenSliderInstance, KeenSliderHooks } from "keen-slider";

import type { Game } from "@/types";

withDefaults(
	defineProps<{
		games: Game[];
		show?: "grid" | "slider";
		hideFavoriteBtn?: boolean;
		withAnimation?: boolean;
		isSearchPopup?: boolean;
	}>(),
	{ show: "grid" }
);
const emit = defineEmits<{ (event: "close"): void }>();

const { t } = useT();
const { handleToggleToFavoriteClick } = useAddGameToFavorite({ t, toastTheme: "dark", toastPosition: "bottom-center" });

const sliderActiveSlide = ref(0);
const sliderOptions: KeenSliderOptions = {
	loop: false,
	slides: {
		perView: "auto",
		spacing: 16
	},
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	}
};

const handleClose = () => {
	emit("close");
};

const isButtonDisabled = (slider: KeenSliderInstance<{}, {}, KeenSliderHooks> | undefined) => {
	if (!slider || typeof slider?.options?.slides !== "object" || typeof slider.options.slides?.perView !== "number") {
		return false;
	}
	const { perView } = slider.options.slides;

	return sliderActiveSlide.value + perView >= slider.slides.length || slider.track.details.slides.length <= perView;
};
</script>

<template>
	<template v-if="show === 'grid'">
		<transition-group
			:name="withAnimation ? 'list-complete' : ''"
			:class="['grid-cards', { 'list-complete': withAnimation }]"
			tag="div"
		>
			<MGame
				v-for="game in games"
				:key="game.id ?? 0"
				:game="game"
				:hideFavoriteBtn="hideFavoriteBtn"
				hideTitle
				:isSearchPopup="isSearchPopup"
				@toggle-favorite="handleToggleToFavoriteClick"
				@close="handleClose"
			/>
		</transition-group>
	</template>

	<div v-if="show === 'slider'" class="game-slider">
		<ASlider v-slot="{ slider }" :options="sliderOptions">
			<AButtonGroup class="keen-nav">
				<AButton variant="secondary" :disabled="sliderActiveSlide === 0" data-tid="slider-prev" @click="slider?.prev()"
					><NuxtIcon name="16/arrow-back" filled
				/></AButton>
				<AButton variant="secondary" :disabled="isButtonDisabled(slider)" data-tid="slider-next" @click="slider?.next()"
					><NuxtIcon name="16/arrow-right" filled
				/></AButton>
			</AButtonGroup>
			<MGame
				v-for="game in games"
				:key="game.id"
				:game="game"
				:hideFavoriteBtn="hideFavoriteBtn"
				class="keen-slider__slide"
				@toggle-favorite="handleToggleToFavoriteClick"
			/>
		</ASlider>
	</div>
</template>

<style scoped lang="scss">
.grid-cards {
	display: grid;
	grid-gap: gutter(2);
	grid-template-columns: repeat(8, 1fr);

	@media (max-width: 1364px) {
		grid-template-columns: repeat(7, 1fr);
	}

	@media (max-width: 1279px) {
		grid-gap: gutter(1.5);
		grid-template-columns: repeat(6, 1fr);
	}

	@media (max-width: 639px) {
		grid-template-columns: repeat(3, 1fr);
	}

	@media (max-width: 385px) {
		grid-template-columns: repeat(2, 1fr);
	}
}
.game-slider:deep(.keen-slider) {
	overflow: initial;
	width: calc(100% - 64px);
	margin-left: gutter(4);

	@include media-breakpoint-down(lg) {
		width: calc(100% - 32px);
		margin-left: gutter(2);
	}

	.keen-nav {
		position: absolute;
		top: -60px;
		right: 0;
		display: flex;
		gap: gutter(0.5);

		@include media-breakpoint-down(xl) {
			display: none;
		}

		&:deep(button[disabled]) {
			pointer-events: none;

			.nuxt-icon {
				opacity: 0.35;
			}
		}
	}
}
.list-complete {
	.card-wrapper {
		transition: all 0.6s ease;
	}
	.list-complete-enter-from,
	.list-complete-leave-to {
		opacity: 0;
		transform: translateY(30px);
	}

	.list-complete-leave-active {
		position: absolute;
	}
}
</style>
<style lang="scss">
.Toastify__toast-container--bottom-center {
	@include media-breakpoint-up(lg) {
		left: auto;
		right: gutter(2);
		transform: translateX(0%);
	}
}
</style>
